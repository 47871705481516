import React, { Suspense, useState, useEffect, useLayoutEffect } from 'react';
import { renderRoutes } from 'react-router-config';
import { LinearProgress, Box, Avatar, IconButton, Tooltip, Popover } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import logoAppweb from '../assets/img/logo_app_web.png'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import PeopleOutlineRoundedIcon from '@mui/icons-material/PeopleOutlineRounded';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  },
  content: {
    flexGrow: 1,
    padding: '20px',
    ['@media (max-width: 767px)']: {
      marginTop: '100px'
    }
  }
}))

const pages = [
  {
    page: '/dashboard',
    label: 'Início',
    icon: <SpaceDashboardOutlinedIcon />
  },
  {
    page: '/meus-testes',
    label: 'Meus testes',
    icon: <FormatListBulletedRoundedIcon />
  },
  {
    page: '/meu-time',
    label: 'Meu time',
    icon: <PeopleOutlineRoundedIcon />
  },
  {
    page: '/minha-conta',
    label: 'Minha conta',
    icon: <AccountCircleOutlinedIcon />
  }
]

const drawerWidth = 240;

const GeneralLayout = props => {

  const classes = useStyles();
  const { route } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className={classes.root}>

      <Suspense fallback={
        <div>
          <LinearProgress />
        </div>
      }>

        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
            position: 'relative'
          }}
          variant="permanent"
          anchor="left"
        >
          <List>
            <ListItem>
              <Typography variant="h5">Make your test</Typography>
            </ListItem>
            <Divider style={{ marginBottom: '10px', marginTop: '10px' }} />
            <Box style={{ paddingLeft: '16px', paddingRight: '6px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar sx={{ width: 36, height: 36 }}>PA</Avatar>
                <Typography style={{ marginLeft: '10px' }}>Patrick Faciroli</Typography>
              </Box>
              <Tooltip title="Menu">
                <IconButton aria-describedby={id} onClick={handleClick}>
                  <KeyboardArrowDownRoundedIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Popover 
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <Box sx={{ width: '200px' }}>
                <List>
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemIcon>
                        <LogoutRoundedIcon/>
                      </ListItemIcon>
                      <ListItemText primary="Sair"/>
                    </ListItemButton>
                  </ListItem>
                </List>
              </Box>
            </Popover>
            <Divider style={{ marginBottom: '10px', marginTop: '10px' }} />
            {pages.map((page, index) => (
              <Link to={page.page} style={{ textDecoration: 'none', color: '#000' }}>
                <ListItem key={index} disablePadding>
                  <ListItemButton selected={window.location.pathname == page.page ? true : false}>
                    <ListItemIcon>
                      {page.icon}
                    </ListItemIcon>
                    <ListItemText primary={page.label} />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
          </List>
          <Box style={{ position: 'absolute', bottom: '20px', left: '16px' }}>
            <img src={logoAppweb} style={{ width: '100px' }} />
          </Box>
        </Drawer>

        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          {renderRoutes(route.routes)}
        </main>

      </Suspense>
    </div>
  );
}

export default GeneralLayout;