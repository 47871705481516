/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import GeneralLayout from './layouts/GeneralLayout';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />
  },
  {
    route: '*',
    component: GeneralLayout,
    routes: [
      {
        path: '/dashboard',
        exact: true,
        component: lazy(() => import('./pages/dashboard/dashboard'))
      },
      {
        path: '/meu-time',
        exact: true,
        component: lazy(() => import('./pages/meu-time/meu-time'))
      },
      {
        path: '/meus-testes',
        exact: true,
        component: lazy(() => import('./pages/meus-testes/meus-testes'))
      },
      {
        path: '/meus-testes/:id',
        exact: true,
        component: lazy(() => import('./pages/meus-testes/meus-testes'))
      },
      {
        path: '/minha-conta',
        exact: true,
        component: lazy(() => import('./pages/minha-conta/minha-conta'))
      },
      {
        path: '/novo-teste',
        exact: true,
        component: lazy(() => import('./pages/novo-teste/novo-teste'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];

export default routes;
