import './App.css';
import { createBrowserHistory } from 'history';
import { renderRoutes } from 'react-router-config';
import { Router } from 'react-router-dom';
import routes from './routes';
import { useEffect } from 'react';
import { logout } from './utils/logout';
import api from './utils/api';

const history = createBrowserHistory();
const url = window.location.href;

function App() {

  // useEffect(() => {

  //   if(!url.includes('auth/login')){

  //     api.get('/me', {
  //       headers: {
  //         'Authorization': 'Bearer ' + localStorage.getItem('tokenStorage')
  //       }
  //     })
  //     .then(res => {})
  //     .catch(res => {
  //       logout();
  //     })

  //   }
    
  // }, [0]);

  return (
    <>
      <Router history={history}>
        {renderRoutes(routes)}
      </Router>
    </>
  );
}

export default App;
